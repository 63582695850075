import Head from 'next/head';
import Link from 'next/link';

export default function PageNotFound() {
  return (
    <>
      <Head>
        <title>Page not found | York Round Table | #DoMore</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="mx-auto w-full max-w-screen-xl">
        <article className="mx-auto w-full max-w-screen-lg">
          <h1 className="text-4xl text-rt-orange text-center mb-10">Page not found</h1>
          <div className="mx-auto w-full max-w-screen-md text-white">
            <p className="mb-4 text-2xl">We couldn&apos;t find the page you were looking for.</p>

            <p className="mb-4">
              Try heading back to the{' '}
              <Link href="/">
                <a className="underline hover:no-underline">homepage</a>
              </Link>
              .
            </p>
          </div>
        </article>
      </div>
    </>
  );
}
